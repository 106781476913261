
html {
  font-family: "Roboto", sans-serif;
}

.container.fill-height{
  align-items: start;
}

.containScroll {
  max-width: 100%;
  overflow-y:auto;
  overflow-x:hidden;
}

//remove link underline
.unstyledLink
{
  text-decoration:none;
}

//fix text-right on inputs
.v-input.text-right .v-text-field__slot input {
  text-align: right;
}

.centered-input  input {
  text-align: center !important;
}

//remove arrows from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//remove arrows from number input
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//fix snackbars to top
.v-snack--absolute {
  .v-snack__wrapper {
    position: fixed;
  }
}

.blur {
  -webkit-filter: blur(4px); /* Chrome, Safari, Opera */
  filter: blur(4px);
}

